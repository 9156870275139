import React, { Component } from "react";

class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupname: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleKeyPress(event) {
    if (event.keyCode === 13) {
      this.props.onSubmit(this.state.groupname);
    }
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  Link() {
    if (
      !this.props.content.link ||
      !this.props.content.link.url ||
      !this.props.content.link.title
    ) {
      return false;
    }
    return (
      <a
        className="text-white normal btn btn-primary my-4"
        href={this.props.content.link.url}
      >
        {this.props.content.link.title}
      </a>
    );
  }
  Image() {
    if (
      !this.props.content.image ||
      !this.props.content.image.url ||
      !this.props.content.image.title
    ) {
      return false;
    }
    return (
      <div className="my-4">
        <img
          className="d-block img-fluid"
          src={this.props.content.image.url}
          title={this.props.content.image.title}
        />
        <small>{this.props.content.image.title}</small>
      </div>
    );
  }
  Youtube() {
    if (!this.props.content.youtube || !this.props.content.youtube.code) {
      return false;
    }
    return (
      <iframe
        width="560"
        height="315"
        src={"https://www.youtube.com/embed/" + this.props.content.youtube.code}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    );
  }
  render() {
    return (
      <div>
        <div className="content p-5">
          <h4>{this.props.content.title}</h4>
          <p>{this.props.content.description}</p>
          {this.Link()}
          {this.Image()}
          {this.Youtube()}
        </div>
        <div className="row no-gutters">
          <div className="col-8">
            <input
              name="groupname"
              className="form-control p-4 d-flex h-100"
              value={this.state.groupname}
              placeholder="Groepsnaam"
              onChange={this.handleInputChange}
              onKeyDown={(e) => this.handleKeyPress(e)}
            ></input>
          </div>
          <div className="col-4">
            <button
              disabled={!this.state.groupname}
              className="btn btn-primary text-white btn-block btn-lg p-4"
              onClick={() => this.props.onSubmit(this.state.groupname)}
            >
              VERDER
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
