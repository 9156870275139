import React, { Component } from "react";
import classNames from "classnames";

class Alert extends Component {
  render() {
    const alertClass = classNames("alert-message p-4", {
      "bg-danger": this.props.lastStatus === "danger",
      "bg-success": this.props.lastStatus === "success",
      hide: !this.props.visible,
      show: this.props.visible,
    });
    return (
      <div key={this.props.lastMessage} className={alertClass}>
        {this.props.lastMessage} &nbsp;
      </div>
    );
  }
}

export default Alert;
