import React, { Component } from "react";
import classNames from "classnames";

class Clock extends Component {
  getTimeFormatted() {
    if (!this.props.time || this.props.time < 1) {
      return "00:00:00";
    }
    var date = new Date(null);
    date.setSeconds(this.props.time);
    var result = date.toISOString().substr(11, 8);
    return result;
  }
  render() {
    const clockClass = classNames("clock", {
      flash: this.props.isFlashing,
      hidden: this.props.isHidden,
      running: this.props.isRunning,
      finished: this.props.isFinished,
    });
    return (
      <div className="clock-wrapper">
        <div className={clockClass}>{this.getTimeFormatted()}</div>
      </div>
    );
  }
}

export default Clock;
