import React, { Component } from "react";

class Start extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleKeyPress(event) {
    if (event.keyCode === 13) {
      this.props.onSubmit(this.state.code);
    }
  }
  render() {
    return (
      <div>
        <div className="content p-5">
          <h4>Welkom bij ESCAPETHEBOX</h4>
          <p>Voeg je spelcode in.</p>
        </div>
        <div className="row no-gutters">
          <div className="col-8">
            <input
              name="code"
              className="form-control p-4 d-flex h-100"
              value={this.state.code}
              placeholder="Spelcode"
              onChange={this.handleInputChange}
              onKeyDown={(e) => this.handleKeyPress(e)}
            ></input>
          </div>
          <div className="col-4">
            <button
              disabled={!this.state.code}
              className="btn btn-primary text-white btn-block btn-lg p-4"
              onClick={() => this.props.onSubmit(this.state.code)}
            >
              VERDER
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Start;
