import React, { Component } from "react";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleKeyPress(event) {
    if (event.keyCode === 13) {
      this.submitAnswer();
    }
  }
  submitAnswer() {
    this.props.onSubmit(this.state.answer, this.state.tipsShown);
    this.setState({
      answer: "",
    });
  }
  getInputValue() {
    if (this.props.isLastQuestion) {
      return this.state.answer;
    }
    if (
      this.props.question.triedAnswers &&
      this.props.question.triedAnswers.length > 0
    ) {
      return this.props.question.triedAnswers[
        this.props.question.triedAnswers.length - 1
      ];
    }

    return "";
  }
  Link() {
    if (
      !this.props.question.link ||
      !this.props.question.link.url ||
      !this.props.question.link.title
    ) {
      return false;
    }
    return (
      <a
        className="text-white normal btn btn-primary my-4"
        href={this.props.question.link.url}
      >
        {this.props.question.link.title}
      </a>
    );
  }
  Image() {
    if (
      !this.props.question.image ||
      !this.props.question.image.url ||
      !this.props.question.image.title
    ) {
      return false;
    }
    return (
      <div className="my-4">
        <img
          className="d-block img-fluid"
          src={this.props.question.image.url}
          title={this.props.question.image.title}
        />
        <small>{this.props.question.image.title}</small>
      </div>
    );
  }
  Youtube() {
    if (!this.props.question.youtube || !this.props.question.youtube.code) {
      return false;
    }
    return (
      <iframe
        width="560"
        height="315"
        src={
          "https://www.youtube.com/embed/" + this.props.question.youtube.code
        }
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    );
  }
  Tips() {
    // if (!this.props.isLastQuestion) {
    //   return false;
    // }
    let tipsShown =
      this.props.question.tipsShown !== undefined
        ? this.props.question.tipsShown
        : 0;
    const listItems = this.props.question.tips
      .slice(0, tipsShown)
      .map((tip, index) => (
        <div key={index} className="alert alert-info" role="alert">
          <strong>TIP #{index + 1}</strong> {tip}
        </div>
      ));

    let label;
    if (this.props.question.tips.length <= tipsShown) {
      label = "";
    } else if (tipsShown > 0) {
      label = "Vraag nog een tip -" + this.props.penalty + "s";
    } else {
      label = "Even vast? Vraag een tip -" + this.props.penalty + "sec";
    }

    return (
      <div>
        {listItems}
        <button
          className="text-info font-weight-bold btn btn-link p-0"
          onClick={() => this.props.onGetTip()}
        >
          {label}
        </button>
      </div>
    );
  }
  render() {
    return (
      <div key={this.props.currentQuestion} className="fade-in-right">
        <div className="content p-5">
          <h4>{this.props.question.title}</h4>
          <p>{this.props.question.description}</p>
          {this.Link()}
          {this.Image()}
          {this.Youtube()}
          {this.Tips()}
        </div>
        <div className="row no-gutters">
          <div className="col-8">
            <input
              name="answer"
              className="form-control p-4 d-flex h-100"
              value={this.getInputValue()}
              onChange={this.handleInputChange}
              onKeyDown={(e) => this.handleKeyPress(e)}
              disabled={!this.props.isLastQuestion}
              placeholder={
                this.props.question.placeholder
                  ? this.props.question.placeholder
                  : "Antwoord"
              }
            ></input>
          </div>
          <div className="col-4">
            <button
              disabled={!this.state.answer || !this.props.isLastQuestion}
              className="btn btn-primary text-white btn-block btn-lg p-4"
              onClick={() => this.submitAnswer()}
            >
              CONTROLEER
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Question;
