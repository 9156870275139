import React, { Component } from "react";
import classNames from "classnames";

class Outro extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    setInterval(() => this.changeBackgroundColor(), 3000);
  }
  changeBackgroundColor() {
    let colors = ["#2bb776", "#34a13c", "#76c131", "#72ab15"];
    colors = ["#ccc2ab"];
    document.body.style.backgroundColor =
      colors[Math.floor(Math.random() * colors.length)];
  }
  getTimeFormatted(seconds) {
    var date = new Date(null);
    date.setSeconds(seconds);
    var result = date.toISOString().substr(11, 8);
    return result;
  }
  renderRankingItems() {
    if (!this.props.rankingItems) {
      return false;
    }
    return Object.values(this.props.rankingItems).map((rankingItem, index) => (
      <tr
        key={index}
        className={classNames({
          "bg-primary": rankingItem.code === this.props.code,
        })}
      >
        <td>{index + 1}</td>
        <td>{rankingItem.groupname}</td>
        <td>{this.getTimeFormatted(rankingItem.time_played)}</td>
      </tr>
    ));
  }
  Link() {
    if (
      !this.props.content.link ||
      !this.props.content.link.url ||
      !this.props.content.link.title
    ) {
      return false;
    }
    return (
      <a
        className="text-white normal btn btn-primary my-4"
        href={this.props.content.link.url}
      >
        {this.props.content.link.title}
      </a>
    );
  }
  Image() {
    if (
      !this.props.content.image ||
      !this.props.content.image.url ||
      !this.props.content.image.title
    ) {
      return false;
    }
    return (
      <div className="my-4">
        <img
          className="d-block img-fluid"
          src={this.props.content.image.url}
          title={this.props.content.image.title}
        />
        <small>{this.props.content.image.title}</small>
      </div>
    );
  }
  Youtube() {
    if (!this.props.content.youtube || !this.props.content.youtube.code) {
      return false;
    }
    return (
      <iframe
        width="560"
        height="315"
        src={"https://www.youtube.com/embed/" + this.props.content.youtube.code}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    );
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-7">
          <div className="content p-5">
            <h4>{this.props.content.title}</h4>
            <p>{this.props.content.description}</p>
            {this.Link()}
            {this.Image()}
            {this.Youtube()}
          </div>
        </div>
        <div className="col-md-5 ranking-wrapper">
          <table className="table ranking-table mb-0">
            <thead>
              <tr>
                <th>Plaats</th>
                <th>Groepsnaam</th>
                <th>Gespeelde tijd</th>
              </tr>
            </thead>
            <tbody>{this.renderRankingItems()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Outro;
