import React, { Component } from "react";
import Clock from "./Clock";
import Question from "./Question";
import Start from "./Start";
import Intro from "./Intro";
import Outro from "./Outro";
import Tabs from "./Tabs";
import Alert from "./Alert";
import logo from "./escapethebox-logo.png";
import axios from "axios";
import Confetti from "react-confetti";

class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      groupname: "",
      initalTime: 7200,
      time: 7200,
      isStarted: false,
      isFinished: false,
      isClockFlashing: false,
      intervalId: null,
      alertIntervalId: null,
      intro: {},
      questions: [],
      outro: {},
      currentQuestion: 0,
      lastQuestion: 0,
      lastMessage: "",
      lastStatus: "",
      alertVisible: false,
    };
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }
  tick() {
    if (this.state.time < 1) {
      this.setAlert(
        "Helaas de tijd is op. Je kan wel nog verder spelen.",
        "danger"
      );
    }
    this.setState({
      time: this.state.time - 1,
    });
  }
  loadGame(code) {
    axios
      .post(`https://escapethebox.tomdupont.be/api/game/start/`, {
        code: code,
      })
      .then((res) => {
        if (res.data.success === false) {
          this.setAlert(res.data.message, "danger");
          return false;
        }
        let data = res.data.data;
        document.body.style.backgroundColor = data.color;

        this.setState({
          code: code,
          initalTime: data.time,
          time: data.time,
          penalty: data.penalty,
          intro: data.intro,
          questions: data.questions,
          outro: data.outro,
        });
      });
  }
  finishGame() {
    clearInterval(this.state.intervalId);
    this.setState({
      isRunning: false,
      isFinished: true,
    });
    let timeLeft = this.state.time;
    let timePlayed = this.state.initalTime - timeLeft;
    axios
      .post(`https://escapethebox.tomdupont.be/api/game/finish/`, {
        groupname: this.state.groupname,
        code: this.state.code,
        timeLeft: timeLeft,
        timePlayed: timePlayed,
        questions: this.state.questions,
      })
      .then((res) => {
        if (res.data.success === false) {
          this.setAlert(res.message, "danger");
          return;
        }
        this.setState({
          ranking: res.data.ranking,
        });
      });
  }
  startGame(groupname) {
    const intervalId = setInterval(() => this.tick(), 1000);
    this.setState({
      groupname: groupname,
      isStarted: true,
      intervalId: intervalId,
    });
  }
  goToNextQuestion() {
    // Check if there is a next question => Otherwise go to end screen
    if (this.state.currentQuestion + 1 < this.state.questions.length) {
      // Next question
      this.setState({
        lastQuestion: this.state.lastQuestion + 1,
        currentQuestion: this.state.currentQuestion + 1,
      });
    } else {
      // Game end
      this.finishGame();
    }
  }
  handleCheck(answer, tries) {
    const currentQuestion = this.state.questions[this.state.currentQuestion];
    const answersMapped = currentQuestion.answers.map((x) =>
      String(x).toUpperCase()
    );
    let triedAnswers = [];
    // Add the tried awnser to the question
    if (currentQuestion.triedAnswers) {
      triedAnswers = currentQuestion.triedAnswers;
    }
    console.log(triedAnswers, "triedAnswers");

    triedAnswers.push(answer);

    this.setState(
      {
        questions: this.state.questions.map((question, index) => {
          if (index === this.state.currentQuestion) {
            return { ...question, triedAnswers: triedAnswers };
          }
          return question;
        }),
      },
      () => {
        if (answersMapped.includes(answer.toUpperCase())) {
          // Goed antwoord
          this.setAlert(
            "Yes! " + answer + " is het juist antwoord.",
            "success"
          );
          this.goToNextQuestion();
        } else {
          // Fout antwoord
          this.handleTimePenalty(this.state.penalty);

          // Are there more tips available? Otherwise go to next question
          if (this.getTipsShowns() < currentQuestion.tips.length) {
            this.handleGetTip();
            this.setAlert(
              answer +
                " is niet het juist antwoord." +
                " Er is een extra tip beschikbaar.",
              "danger"
            );
          } else {
            this.goToNextQuestion();
            this.setAlert(
              "Fout! " + answersMapped[0] + " was het juiste antwoord.",
              "danger"
            );
          }
        }
      }
    );
  }
  getTipsShowns() {
    const currentQuestion = this.state.questions[this.state.currentQuestion];
    return currentQuestion.tipsShown !== undefined
      ? currentQuestion.tipsShown
      : 0;
  }
  handleGetTip() {
    const tipsShown = this.getTipsShowns();
    this.setState({
      questions: this.state.questions.map((question, index) => {
        if (index === this.state.lastQuestion) {
          return { ...question, tipsShown: tipsShown + 1 };
        }
        return question;
      }),
    });

    // Penalty time
    this.handleTimePenalty(this.state.penalty);
  }
  handleTimePenalty(seconds) {
    this.setState({
      time: this.state.time - seconds,
      isClockFlashing: true,
    });
    setTimeout(() => {
      this.setState({
        isClockFlashing: false,
      });
    }, 300);
  }
  switchQuestion(index) {
    console.log(index, "switch item to index");
    this.setState({
      currentQuestion: index,
    });
  }
  setAlert(message, status) {
    // Clear exesting intervalId
    clearTimeout(this.state.alertIntervalId);
    const alertIntervalId = setTimeout(() => {
      this.clearAlert();
    }, 5000);
    this.setState({
      lastMessage: message,
      lastStatus: status,
      alertVisible: true,
      alertIntervalId: alertIntervalId,
    });
  }
  clearAlert() {
    this.setState({
      alertVisible: false,
    });
  }

  renderCurrentItem() {
    if (this.state.isFinished) {
      return (
        <div>
          <Confetti width="1920" height="1080" />
          <Tabs
            items={this.state.questions}
            lastItem={this.state.lastQuestion}
            currentItem={this.state.currentQuestion + 1}
            onSwitchItem={(toIndex) => this.switchQuestion(toIndex)}
          />
          <div className="card">
            <Outro
              rankingItems={this.state.ranking}
              code={this.state.code}
              content={this.state.outro}
            />
          </div>
        </div>
      );
    } else if (this.state.isStarted && this.state.questions) {
      // Game is running show question
      return (
        <div>
          <Tabs
            items={this.state.questions}
            lastItem={this.state.lastQuestion}
            currentItem={this.state.currentQuestion}
            onSwitchItem={(toIndex) => this.switchQuestion(toIndex)}
          />
          <div className="card">
            <Question
              onSubmit={(answer, tries) => this.handleCheck(answer, tries)}
              onGetTip={() => this.handleGetTip()}
              question={this.state.questions[this.state.currentQuestion]}
              penalty={this.state.penalty}
              currentQuestion={this.state.currentQuestion}
              isLastQuestion={
                this.state.lastQuestion === this.state.currentQuestion &&
                !this.state.isFinished
              }
            />
          </div>
        </div>
      );
    } else if (this.state.code.length < 1) {
      return (
        <div className="card">
          <Start onSubmit={(code) => this.loadGame(code)} />
        </div>
      );
    } else if (!this.state.groupname) {
      return (
        <div className="card">
          <Intro
            content={this.state.intro}
            onSubmit={(groupname) => this.startGame(groupname)}
          />
        </div>
      );
    }
  }
  render() {
    return (
      <div>
        <header className="App-header d-flex justify-content-between flex-column-reverse flex-lg-row align-items-center">
          <img src={logo} width="240" className="" alt="logo" />
          <Clock
            time={this.state.time}
            isFlashing={this.state.isClockFlashing}
            isHidden={this.state.code.length < 1}
            isFinished={this.state.isFinished}
            isRunning={this.state.isStarted && !this.state.isFinished}
          />
          <h1 className="d-none d-lg-block">
            escape<small>the</small>box
          </h1>
        </header>
        <div className="my-5">
          <main className="">{this.renderCurrentItem()}</main>
          <Alert
            lastStatus={this.state.lastStatus}
            lastMessage={this.state.lastMessage}
            visible={this.state.alertVisible}
          />
        </div>
      </div>
    );
  }
}

export default Game;
