import React, { Component } from "react";
import classNames from "classnames";

class Tabs extends Component {
  renderItems() {
    const listItems = this.props.items.map((item, index) => {
      if (index > this.props.lastItem) {
        return false;
      }
      return (
        <li className="nav-item" key={index}>
          <a
            className={classNames("nav-link", {
              active: this.props.currentItem === index,
              success:
                item.triedAnswers &&
                !item.tipsShown &&
                item.triedAnswers.length === 1,
              warning: item.tipsShown && item.tipsShown > 1,
              danger: item.tipsShown && item.tipsShown == item.tips.length,
            })}
            onClick={() => this.props.onSwitchItem(index)}
            href="#"
          >
            {index + 1}
          </a>
        </li>
      );
    });
    return listItems;
  }
  render() {
    return <ul className="nav nav-pills">{this.renderItems()}</ul>;
  }
}

export default Tabs;
